@font-face {
    font-family: 'Recoleta';
    src: local('Recoleta'), url('../public/fonts/Recoleta-RegularDEMO.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Recoletabold';
    src: local('Recoleta Bold'), url('../public/fonts/Recoleta-SemiBold.woff') format('woff');
 
  }

  @font-face {
    font-family: 'Recoleta regular';
    src: local('Recoleta regular'), url('../public/fonts/RecoletaAlt-Regular.woff') format('woff');

  }

  @font-face {
    font-family: 'Recoleta thin';
    src: local('Recoleta thin'), url('../public/fonts/RecoletaAlt-Thin.woff') format('woff');

  }

  @font-face {
    font-family: 'Recoleta light';
    src: local('Recoleta thin'), url('../public/fonts/RecoletaAlt-Light.woff') format('woff');

  }


  